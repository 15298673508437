import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa"; // Importing icons from react-icons
import { LuTreePalm } from "react-icons/lu";

const navigation = [
  { name: "Home", href: "#home" },
  { name: "About", href: "#about" },
  { name: "Projects", href: "#projects" },
  { name: "Contact", href: "#contact" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [currentHash, setCurrentHash] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      setCurrentHash(window.location.hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll("section");

    const handleScroll = () => {
      let currentSection = "";
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          currentSection = `#${section.id}`;
        }
      });
      setCurrentHash(currentSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = (hash) => {
    setCurrentHash(hash);
    window.location.hash = hash;
  };

  return (
    <Disclosure as="nav" className="bg-black sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="w-full px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center">
                {/* Left Section: Icon */}
                <a
                  href="#home"
                  className="text-white text-2xl font-bold hover:text-gray-300 custom-nav-link"
                >
                  <LuTreePalm className="h-8 w-8 hover:text-green-800" />
                </a>
              </div>
              {/* Center Section: Navigation Items */}
              <div className="hidden sm:flex sm:space-x-4 mx-auto">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    onClick={() => handleClick(item.href)}
                    className={classNames(
                      currentHash === item.href
                        ? "text-green-800 underline custom-nav-link"
                        : "text-gray-300 hover:text-green-800 custom-nav-link",
                      "px-3 py-2 rounded-md text-sm font-medium"
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              {/* Right Section: Hamburger Menu and Social Media Icons */}
              <div className="flex items-center space-x-4">
                <div className="sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="hidden sm:flex items-center space-x-4">
                  <a
                    href="https://github.com/cfonseca24"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-white"
                  >
                    <FaGithub className="h-6 w-6" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/christopher-fonseca-822434b9/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-white"
                  >
                    <FaLinkedin className="h-6 w-6" />
                  </a>
                  <a
                    href="mailto:your-email@example.com"
                    className="text-gray-400 hover:text-white"
                  >
                    <FaEnvelope className="h-6 w-6" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  onClick={() => handleClick(item.href)}
                  className={classNames(
                    currentHash === item.href
                      ? "text-green-800 underline custom-nav-link"
                      : "text-gray-300 hover:text-green-800 custom-nav-link",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              <div className="flex justify-center space-x-4 pt-4">
                <a
                  href="https://github.com/cfonseca24"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white"
                >
                  <FaGithub className="h-6 w-6" />
                </a>
                <a
                  href="https://www.linkedin.com/in/christopher-fonseca-822434b9/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white"
                >
                  <FaLinkedin className="h-6 w-6" />
                </a>
                <a
                  href="failto:fonseca.j.chris@gmail.com"
                  className="text-gray-400 hover:text-white"
                >
                  <FaEnvelope className="h-6 w-6" />
                </a>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

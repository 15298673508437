import React from "react";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="bg-black text-white py-4">
      <div className="container mx-auto flex flex-col items-center">
        <div className="flex space-x-4 mb-4">
          <a
            href="https://github.com/cfonseca24"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white"
          >
            <FaGithub className="h-6 w-6" />
          </a>
          <a
            href="https://www.linkedin.com/in/christopher-fonseca-822434b9/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white"
          >
            <FaLinkedin className="h-6 w-6" />
          </a>
          <a
            href="mailto:fonseca.j.chris@gmail.com"
            className="text-gray-400 hover:text-white"
          >
            <FaEnvelope className="h-6 w-6" />
          </a>
        </div>
        <p className="text-sm">
          Chris Fonseca &copy; {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
}

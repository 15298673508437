import React from "react";
import videoBackground from "../../assets/southbeach.mp4";
import "../../App.css";

function LandingPage() {
  return (
    <main className="relative">
      <section id="home" className="section">
        <div className="video-background">
          <video
            id="background-video"
            autoPlay
            loop
            muted
            className="absolute object-cover w-full h-full"
          >
            <source src={videoBackground} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="relative flex flex-col justify-center items-center min-h-screen px-8">
          <h1 className="text-6xl text-green-100 font-bold cursive leading-none lg:leading-snug home-name">
            Olá. I'm Chris.
          </h1>
          <p className="text-2xl text-white mt-4">Software Engineer</p>
          <a
            href="#projects"
            className="mt-8 bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
          >
            View My Projects
          </a>
        </div>
      </section>
    </main>
  );
}

export default LandingPage;

import React from "react";
import {
  FaJava,
  FaReact,
  FaAngular,
  FaGit,
  FaDatabase,
  FaCss3Alt,
  FaHtml5,
  FaNodeJs,
} from "react-icons/fa";
import { SiSpringboot, SiMysql, SiTypescript, SiDotnet } from "react-icons/si";
import { TbBrandCSharp } from "react-icons/tb";
import image2 from "../../assets/wynwood.jpg"; // Replace with your image path

function About() {
  return (
    <section
      id="about"
      className="min-h-screen relative flex flex-col justify-center items-center px-8"
    >
      <img
        src={image2}
        alt="Background"
        className="absolute object-cover w-full h-full"
      />
      <div className="absolute inset-0 bg-black opacity-50"></div>{" "}
      {/* Background overlay */}
      <div className="relative z-10 text-center container mx-auto flex flex-col justify-center items-center">
        <h1 className="text-4xl text-white font-bold mb-8 cursive mt-24">
          ABOUT
        </h1>
        <div
          id="about"
          className="bg-black bg-opacity-80 rounded-lg shadow-2xl p-8 lg:p-28"
        >
          <div className="lg:flex">
            <img
              src="./icons8-palm-tree-96.png"
              className="rounded-full lg:h-40 lg:w-40 md:w-32 md:h-32 mr-8"
              alt="author"
            />
            <div className="text-lg flex flex-col justify-center">
              <h1 className="cursive text-4xl text-green-300 mb-4">
                Oi there. I'm
                <span className="text-green-100"> Chris Fonseca</span>
              </h1>
              <div className="prose lg:prose-xl text-white text-sm">
                I am a student at Florida International University, studying
                Computer Science. I began learning programming on my own and
                decided to further my education in the field. I aim to pursue a
                career in software engineering.
              </div>
            </div>
          </div>
          <div className="mt-12">
            {" "}
            {/* Add more space here */}
            <h2 className="text-3xl text-white font-bold mb-4">Skills</h2>
            <div className="flex flex-wrap justify-center space-x-4">
              <div className="flex flex-col items-center">
                <FaJava className="text-white text-4xl mb-2" />
                <span className="text-white">Java</span>
              </div>
              <div className="flex flex-col items-center">
                <FaReact className="text-white text-4xl mb-2" />
                <span className="text-white">React</span>
              </div>
              <div className="flex flex-col items-center">
                <FaAngular className="text-white text-4xl mb-2" />
                <span className="text-white">Angular</span>
              </div>
              <div className="flex flex-col items-center">
                <SiSpringboot className="text-white text-4xl mb-2" />
                <span className="text-white">Spring Boot</span>
              </div>
              <div className="flex flex-col items-center">
                <FaGit className="text-white text-4xl mb-2" />
                <span className="text-white">Git</span>
              </div>
              <div className="flex flex-col items-center">
                <FaDatabase className="text-white text-4xl mb-2" />
                <span className="text-white">SQL</span>
              </div>
              <div className="flex flex-col items-center">
                <SiMysql className="text-white text-4xl mb-2" />
                <span className="text-white">MySQL</span>
              </div>
              <div className="flex flex-col items-center">
                <FaCss3Alt className="text-white text-4xl mb-2" />
                <span className="text-white">CSS</span>
              </div>
              <div className="flex flex-col items-center">
                <FaHtml5 className="text-white text-4xl mb-2" />
                <span className="text-white">HTML</span>
              </div>
              <div className="flex flex-col items-center">
                <SiTypescript className="text-white text-4xl mb-2" />
                <span className="text-white">TypeScript</span>
              </div>
              <div className="flex flex-col items-center">
                <FaNodeJs className="text-white text-4xl mb-2" />
                <span className="text-white">Node.js</span>
              </div>
              <div className="flex flex-col items-center">
                <TbBrandCSharp className="text-white text-4xl mb-2" />
                <span className="text-white">C#</span>
              </div>
              <div className="flex flex-col items-center">
                <SiDotnet className="text-white text-4xl mb-2" />
                <span className="text-white">.NET</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;

import React from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import { FaGithub } from "react-icons/fa"; // Importing GitHub icon from react-icons
import { projects } from "../../data/data"; // Assuming you have a projects data file
import image5 from "../../assets/airplane.jpg"; // Background image

function Projects() {
  return (
    <section
      id="projects"
      className="min-h-screen relative flex flex-col justify-center items-center px-8 bg-gray-700"
    >
      <img
        src={image5}
        alt="Background"
        className="absolute object-cover w-full h-full z-0"
      />
      <div className="absolute inset-0 bg-black opacity-50"></div>{" "}
      {/* Background overlay */}
      <div className="relative z-10 text-center container mx-auto flex flex-col justify-center items-center">
        <h1 className="text-4xl text-white font-bold mb-8 cursive mt-24">
          PROJECTS
        </h1>
        <MDBCarousel
          showControls
          showIndicators
          dark
          fade
          className="w-full h-full"
          interval={12000}
        >
          {projects.map((project, index) => (
            <MDBCarouselItem
              key={index}
              className="w-100 d-block h-full"
              itemId={index + 1}
            >
              <div className="flex flex-col md:flex-row items-center bg-black bg-opacity-80 rounded-lg shadow-lg overflow-hidden max-w-4xl mx-auto p-4 md:p-6">
                <div className="w-full md:w-2/3 h-80 md:h-96 flex items-center justify-center">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="object-contain h-full"
                  />
                </div>
                <div className="p-4 md:p-6 flex flex-col justify-center md:w-1/3 text-left fixed-height">
                  <h5 className="text-gray-100 text-xl font-bold mb-2 one-line">
                    {project.title}
                  </h5>
                  <p className="text-gray-100 text-base mb-4">
                    {project.description}
                  </p>
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline flex items-center"
                  >
                    <FaGithub className="mr-2" /> View Project
                  </a>
                </div>
              </div>
            </MDBCarouselItem>
          ))}
        </MDBCarousel>
      </div>
    </section>
  );
}

export default Projects;

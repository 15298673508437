export const projects = [
  {
    title: "7 Arts Real Estate",
    subtitle: "MongoDB, Express, React, Node",
    description:
      "Real Estate Broker Dashboard: A robust platform for brokers to manage property listings, track agent performance, oversee client interactions, and monitor reviews, all in one place.",
    image: "./sevenArts.gif",
    link: "https://github.com/cfonseca24/sevenArtsRealEstate",
  },
  {
    title: "Roman Fuller Personal/Business Website",
    subtitle: "JavaScript, HTML, CSS",
    description:
      "Personal/Business Website for Client (Musician): Designed and developed a custom website for a client to showcase their portfolio, promote events, and offer access to music and merchandise, boosting their online presence and fan engagement.",
    image: "./roman.gif",
    link: "https://romanfuller.netlify.app/",
  },
  {
    title: "SwingBeat Landing Page",
    subtitle: "Bootstrap, CSS, JavaScript",
    description:
      "Entertainment Company Landing Page: Designed a simple, clean landing page for an entertainment company to provide essential information and create an online presence. ",
    image: "./sbwalkthrough.gif",
    link: "https://github.com/cfonseca2013/SimpleTodo",
  },
  {
    title: "Flixster",
    subtitle: "Java, Android",
    description:
      "Simple Movie App for Android: Developed a basic movie app as part of the CodePath project. The app allows users to browse and view movie details, including ratings, synopses, and trailers, offering a user-friendly experience on Android devices.",
    image: "./flixpt2.gif",
    link: "https://github.com/cfonseca2013/flixster",
  },
  {
    title: "MoodiFy",
    subtitle: "Swift, iOS",
    description:
      "MooDify is an iOS app that analyzes a user's photo to detect their emotional state. Based on the mood, it plays a song from Spotify that matches the user’s current emotions, providing a personalized music experience in real-time.",
    image: "./moodifyport.gif",
  },
  /*
  {
    title: "Epic Todo App",
    subtitle: "React Hooks",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
    image: "./logo512.png",
    link: "https://reactbootcamp.com",
  },*/
];

export const skills = [
  "JavaScript",
  "React",
  "Next.js",
  "Node",
  "GraphQL",
  "Material UI",
];
